import React from "react";
import { useState } from "react";

import { QuestionsInfo } from "./arrays/QuestionsInfo";

import waves2 from '../img/waves2.svg'


function Questions() {
    return(
        <div className="main__section__questions">
            <h1 className="main__section__diplomas__title">Вопрос-ответ</h1>
            <div className="main__section__questions__container">
                {QuestionsInfo.map((data, key) => {
                    return(
                        <div key={key}  className="main__section__questions__container__wrapper">
                            <div className="main__section__questions__container__wrapper__question" data-hystmodal={"#" + data.modal}>
                                <p className="main__section__questions__container__wrapper__question__p">
                                    {data.question}
                                </p>
                            </div>
                        </div>
                    )
                })}
            </div>
            {QuestionsInfo.map((data, key) => {
                return (
                    <div key={key} className="main__section__questions__popUp hystmodal" id={data.modal} aria-hidden="true">
                        <div className="main__section__questions__popUp__modal hystmodal__wrap">
                            <div className="main__section__questions__popUp__modal__content hystmodal__window window__teacher" role="dialog" aria-modal="true">
                                <button data-hystclose className="hystmodal__close">Закрыть</button>
                                <h3 className="main__section__questions__popUp__modal__content__title">
                                    {data.question}
                                </h3>
                                {Object.values(data.answer).map((answer, index) => (
                                    <p className="main__section__questions__popUp__modal__content__answer">
                                        {answer}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                );
            })}
            <div className="main__section__results__container__button" data-hystmodal='#form'>
                <button className="main__section__results__container__button__hover">Попробовать бесплатный урок</button>
            </div>
            <img loading="lazy" src={waves2} alt="waves image" className="main__section__forWhom__waves" />

        </div>
    )
};

export default Questions;