import React from "react";
import { useState } from "react";

import waves2 from '../img/waves2.svg'
import adv1 from '../img/advantage1.svg'
import adv2 from '../img/advantage2.svg'


function ForWhom() {
    //section "forWhom" switcher
    const [selectedGroup, setSelectedGroup] = useState("kids");

    const handleAdultClick = () => {
        setSelectedGroup("adult");
    }

    const handleKidsClick = () => {
        setSelectedGroup("kids");
    }
    return(
        <div className="main__section__forWhom">
            <div className="main__section__forWhom__container">
                <h1 className="main__section__forWhom__container__h1">Для кого?</h1>
                <div className="main__section__forWhom__container__content">
                    <div className="main__section__forWhom__container__content__info">
                        {selectedGroup === "adult" && (
                            <div className="main__section__forWhom__container__content__info__adult">
                                <h3 className="main__section__forWhom__container__content__info__adult__title">Для взрослых</h3>
                                <p className="main__section__forWhom__container__content__info__adult__text">На начальном этапе изучения Вы поймете, нравится ли Вам китайский язык и в будущем Вы сможете повышать его уровень, общаться с носителями языка или связать свою жизнь с Китаем (поступить на языковые курсы в китайские университеты, налаживать бизнес связи и т.д.).</p>
                            </div>
                        )}
                        {selectedGroup === "kids" && (
                            <div className="main__section__forWhom__container__content__info__kids">
                                <h3 className="main__section__forWhom__container__content__info__kids__title">Для детей и подростков</h3>
                                <p className="main__section__forWhom__container__content__info__kids__text">В зависимости от возраста ребят, на начальных этапах идет знакомство с иностранной лексикой, восприятию речи на слух через игровые задания, песни, общение с учениками и преподавателями. Изучаются простые темы: рассказы о себе семье, доме, животных. Далее они получают более углубленные познания по системе транскрипции иероглифов, правильного произношения звуков, письму. По окончании учебы школьники знают более 1500 слов, могут свободно писать и общаться.</p>
                                <h4 className="main__section__forWhom__container__content__info__kids__point">Почему мы рекомендуем Вашему ребенку этот предмет?</h4>
                                <p className="main__section__forWhom__container__content__info__kids__text">Чем раньше Ваш ребенок познакомится с иностранным языком, тем проще ему будет в дальнейшем. Представьте, что ему будет доступен весь познавательный контент на иностранном языке, возможность общаться с детьми из других стран, а в будущем учиться за рубежом или работать в иностранной компании.</p>
                                <h4 className="main__section__forWhom__container__content__info__kids__point">Какие методики мы применяем?</h4>
                                <p className="main__section__forWhom__container__content__info__kids__text">Основа нашей методики: увлекательное приключение. Ребенку должны быть интересны сами занятия и комфортен педагог. Каждый урок — это отдельная тема, в рамках которой ребенок играет, проходит квесты, проявляет творчество и смекалку, а главное учится думать на иностранном языке.</p>
                            </div>
                        )}
                    </div>
                    <div className="main__section__forWhom__container__content__box">
                        <h3 className="main__section__forWhom__container__content__box__title">Для детей от 7 лет, подростков и взрослых, которые хотят познакомится с китайскими языком и культурой с нуля</h3>
                        <div className="main__section__forWhom__container__content__box__choice">
                            <div className="main__section__forWhom__container__content__box__choice__age">
                                <img src={adv1} alt="advantages" className="main__section__forWhom__container__content__box__choice__age__img" />
                                <button onClick={handleAdultClick} className="main__section__forWhom__container__content__box__choice__age__button">Взрослые</button>
                            </div>
                            <div className="main__section__forWhom__container__content__box__choice__age">
                                <img src={adv2} alt="advantages" className="main__section__forWhom__container__content__box__choice__age__img" />
                                <button onClick={handleKidsClick} className="main__section__forWhom__container__content__box__choice__age__button">Дети и подростки</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img src={waves2} alt="waves" className="main__section__forWhom__waves" />
        </div>
    )
}

export default ForWhom;