import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns'; // Импортируем функцию форматирования из библиотеки date-fns

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-cards';

import like from '../img/like.svg';
import points1 from '../img/points-1.svg'
import points2 from '../img/points-2.svg'


const Comments = () => {

  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);



  const [comments, setComments] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios.get('/vk-api')
      .then(response => {
        setComments(response.data.response.items);
        setUsers(response.data.response.profiles);
      })
      .catch(error => {
        console.error("Error fetching VK comments:", error);
      });
  }, []);

  return (
    <div className="main__section__feedback__container">
      <h1 className="main__section__feedback__container__title">Отзывы</h1>
      <div className="main__section__feedback__container__content">
        <Swiper
          className='main__section__feedback__container__content__slider'
          modules={[Navigation, Pagination, A11y, Autoplay]}
          grabCursor={true}
          slidesPerView={2}
          spaceBetween={0}
          navigation
          pagination={{ clickable: true }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={false}
          // loopedSlides={null}
          breakpoints={{
            1325: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          }}
        >
          {comments
            .filter(comment => !comment.text.includes("["))
            .filter(comment => ![219819368, 612415479, 69465283].includes(comment.from_id))
            .filter(comment => users.find(user => user.id === comment.from_id)?.screen_name) // Проверка на наличие screen_name
            .map(comment => {
              const user = users.find(user => user.id === comment.from_id);

              //дата и время коммента
              const unixTime = comment.date; // Unix time, который нужно преобразовать
              // Преобразуем Unix time в объект Date
              const date = new Date(unixTime * 1000); // Умножаем на 1000, так как Unix time в секундах, а Date ожидает миллисекунды
              // Определяем необходимые опции для форматирования
              const dateFormat = 'd MMMM yyyy'; // Формат даты
              const timeFormat = 'HH:mm'; // Формат времени
              // Форматируем дату и время в нужном формате
              const formattedDate = format(date, dateFormat);
              const formattedTime = format(date, timeFormat);
              return (
                <SwiperSlide
                  key={comment.id}
                  className='main__section__feedback__container__content__slider__slide'
                >
                  {width < 501 ? ( /* Проверка ширины экрана */
                    <div className="main__section__feedback__container__content__slider__slide__comment">
                      <img loading="lazy" src={points1} alt="points" className="main__section__feedback__container__content__slider__slide__comment__points1" />
                      <img loading="lazy" src={points2} alt="points" className="main__section__feedback__container__content__slider__slide__comment__points2" />
                      <div className="main__section__feedback__container__content__slider__slide__comment__person">
                        <div className="main__section__feedback__container__content__slider__slide__comment__person__avatar">
                          <img loading="lazy"
                            className="main__section__feedback__container__content__slider__slide__comment__person__avatar__img"
                            src={user ? user.photo_50 : ''}
                            alt="User Avatar"
                          />
                          <p className="main__section__feedback__container__content__slider__slide__comment__avatar__state">
                            {user && user.online_info.is_online ? 'Online' : 'Offline'}
                          </p>
                        </div>
                        <div className="main__section__feedback__container__content__slider__slide__comment__person__links">
                          <a
                            href={"https://vk.com/" + user.screen_name}
                            target='blank'
                            className="main__section__feedback__container__content__slider__slide__comment__person__links__name"
                          >
                            {user ? user.first_name + ' ' + user.last_name : 'Unknown'}
                          </a>
                          <a
                            href={"https://vk.com/topic-196825297_44767087?post=" + comment.id}
                            target='blank'
                            className="main__section__feedback__container__content__slider__slide__comment__person__links__date">
                            {formattedDate} в {formattedTime}
                          </a>
                        </div>
                      </div>
                      <div className="main__section__feedback__container__content__slider__slide__comment__likes">
                        <img loading="lazy" src={like} alt="like" className="main__section__feedback__container__content__slider__slide__comment__likes__img" />
                        {comment.likes.count}
                      </div>
                      <div className="main__section__feedback__container__content__slider__slide__comment__info">
                        <p className="main__section__feedback__container__content__slider__slide__comment__info__text">
                          {comment.text}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="main__section__feedback__container__content__slider__slide__comment">
                      <img loading="lazy" src={points1} alt="points" className="main__section__feedback__container__content__slider__slide__comment__points1" />
                      <img loading="lazy" src={points2} alt="points" className="main__section__feedback__container__content__slider__slide__comment__points2" />
                      <div className="main__section__feedback__container__content__slider__slide__comment__avatar">
                        <img loading="lazy"
                          className="main__section__feedback__container__content__slider__slide__comment__avatar__img"
                          src={user ? user.photo_50 : ''}
                          alt="User Avatar"
                        />
                        <p className="main__section__feedback__container__content__slider__slide__comment__avatar__state">
                          {user && user.online_info.is_online ? 'Online' : 'Offline'}
                        </p>
                      </div>
                      <div className="main__section__feedback__container__content__slider__slide__comment__likes">
                        <img loading="lazy" src={like} alt="like" className="main__section__feedback__container__content__slider__slide__comment__likes__img" />
                        {comment.likes.count}
                      </div>
                      <div className="main__section__feedback__container__content__slider__slide__comment__info">
                        <a
                          href={"https://vk.com/" + user.screen_name}
                          target='blank'
                          className="main__section__feedback__container__content__slider__slide__comment__info__name"
                        >
                          {user ? user.first_name + ' ' + user.last_name : 'Unknown'}
                        </a>
                        <a
                          href={"https://vk.com/topic-186135646_49223570?post=" + comment.id}
                          target='blank'
                          className="main__section__feedback__container__content__slider__slide__comment__info__date">
                          {formattedDate} в {formattedTime}
                        </a>
                        <p className="main__section__feedback__container__content__slider__slide__comment__info__text">
                          {comment.text}
                        </p>
                      </div>
                    </div>
                  )}
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
};

export default Comments;
