import React from "react";

import LeadForm from "./form";
import Advantages from "./Agvantages";
import ForWhom from "./ForWhom";
import About from "./About";
import Rates from "./Rates";
import TeacherSlider from "./TeachersSlider";
import DiplomaSlider from "./DiplomasSlider";
import Comments from "./VkComments";
import Questions from "./Questions";
import Sales from "./sales";
import PaymentForm from "./payForm";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-cards';

import HystModal from "hystmodal";
import '../../node_modules/hystmodal/dist/hystmodal.min.css'

import waves from '../img/waves.svg'
import waves5 from '../img/waves5.svg'
import china from '../img/chinaicon.svg'
import gradient from '../img/gradient.svg'
import up from '../img/up.svg'



function Main() {
    const myModal = new HystModal({
        linkAttributeName: "data-hystmodal",
    });

    const handleUpClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Добавляем плавное скроллирование
        });
    }
    return (
        <main className="main">
            <img src={up} onClick={handleUpClick} alt="logo" className="main__up" />
            <div className="main__choice hystmodal" id='profile' aria-hidden="true">
                <div className="main__choice__modal hystmodal__wrap">
                    <div className="main__choice__modal__layout hystmodal__window window__teacher" role="dialog" aria-modal="true">
                        <button data-hystclose className="hystmodal__close">Закрыть</button>
                        <div className="main__choice__modal__layout__content">
                            <h1 className="main__choice__modal__layout__content__title">Выбрать другой профиль обучения:</h1>
                            <a href="https://my-english.club/" target="blank" className="main__choice__modal__layout__content__a">Я хочу изучать английский язык</a>
                            <a href="https://kids.shineschool.ru/" target="blank" className="main__choice__modal__layout__content__a">Меня интересует дошкольное обучение</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main__section__form hystmodal" id='paymentform' aria-hidden="true">
                <div className="hystmodal__wrap">
                    <div className="hystmodal__window" role="dialog" aria-modal="true">
                        <button data-hystclose className="hystmodal__close">Закрыть</button>
                        <PaymentForm/>
                    </div>
                </div>
            </div>
            <Sales/>
            <section className="main__section">
                <div className="main__section__greeting">
                    <img src={china} alt="scenery" className="main__section__greeting__china1" />
                    <img src={china} alt="scenery" className="main__section__greeting__china2" />
                    <div className="main__section__greeting__content">
                        <a href="tel: +79238281981" className="main__section__greeting__content__number">
                            8-800-550-21-34
                        </a>
                        <div className="main__section__greeting__content__left">
                            <h1 className="main__section__greeting__content__left__h1">Изучение китайского для взрослых и детей, не выходя из дома</h1>
                            <h1 className="main__section__greeting__content__left__p">
                                Занятия онлайн от 430 руб
                            </h1>
                            <div className="main__section__greeting__content__left__button" data-hystmodal='#form'>
                                <button className="main__section__greeting__content__left__button__hover">
                                    Попробовать бесплатный урок
                                </button>
                            </div>
                        </div>
                    </div>
                    <img src={waves} alt="waves" className="main__section__greeting__waves" />
                </div>
                <div className="main__section__form hystmodal" id='form' aria-hidden="true">
                    <div className="hystmodal__wrap">
                        <div className="hystmodal__window" role="dialog" aria-modal="true">
                            <button data-hystclose className="hystmodal__close">Закрыть</button>
                            <LeadForm/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="main__section">
                <Advantages/>
            </section>
            <section id="course" className="main__section">
                <ForWhom/>
            </section>
            <section className="main__section">
                <About/>
            </section>
            <section className="main__section" id="rates">
                <Rates/>
            </section>
            <section className="main__section" id="feedback">
                <div className="main__section__feedback">
                    <Comments/>
                    <img loading="lazy" src={waves5} alt="waves" className="main__section__feedback__waves" />
                </div>
            </section>
            <section className="main__section" id="teachers">
                <img src={gradient} alt="gradient" className="main__section__gradient" />
                <TeacherSlider/>
            </section>
            <section className="main__section">
                <DiplomaSlider/>
            </section>
            <section className="main__section" id="questions">
                <Questions/>
            </section>
        </main>
    )
}


export default Main;