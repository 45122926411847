import React from "react";

import TeacherCard from "./teacherCard";
import { TeacherInfo } from "./arrays/TeacherInfo";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay,  } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-cards';

import arrowMore from '../img/arrowMore.svg'

function TeacherSlider () {
    return(
        <div className="main__section__teachers">
            <h1 className="main__section__teachers__title">Наши преподаватели</h1>
            <div className="main__section__teachers__container">
                <Swiper
                    className='main__section__teachers__container__slider'
                    modules={[Navigation, Pagination, A11y, Autoplay]}
                    grabCursor={true}
                    slidesPerView={3}
                    spaceBetween={0}
                    navigation
                    pagination={{ clickable: true }}
                    // autoplay={{
                    //     delay: 2500,
                    //     disableOnInteraction: false,
                    // }}
                    loop={true}
                    loopedSlides={null}
                    breakpoints={{
                        1520: {
                            slidesPerView: 3,
                            loop:true,
                            loopedSlides:null
                        },
                        // 1200: {
                        //     slidesPerView: 3,
                        //     loop:false
                        // },
                        600: {
                            slidesPerView: 2,
                            loop:true,
                            loopedSlides:null
                        },
                        0: {
                            slidesPerView: 1,
                            loop:true,
                            loopedSlides:null
                            },
                        }}
                >
                    {TeacherInfo.map((data, key) => {
                        return (
                            <SwiperSlide key={key} className='main__section__teachers__container__slider__slide'>
                                <div className="main__section__teachers__container__slider__slide__card">
                                    <img loading="lazy" src={data.img} alt="teacher" className="main__section__teachers__container__slider__slide__card__img" />
                                    <div className="main__section__teachers__container__slider__slide__card__hover">
                                        <div className="main__section__teachers__container__slider__slide__card__hover__container">
                                            <h3 className="main__section__teachers__container__slider__slide__card__hover__container__name">{data.name}</h3>
                                            <p className="main__section__teachers__container__slider__slide__card__hover__container__info">
                                                {data.info[0]}
                                            </p>
                                            <button data-hystmodal={"#" + data.modal} className="main__section__teachers__container__slider__slide__card__hover__container__button">
                                                Подробнее
                                                <img loading="lazy" src={arrowMore} alt="arrow" className="main__section__teachers__container__slider__slide__card__hover__container__button__arrow" />
                                            </button>
                                        </div>
                                        
                                    </div>
                                </div>
                                <h3 className="main__section__teachers__container__slider__slide__name">{data.name}</h3>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
            <TeacherCard/>
        </div>
    )
}


export default TeacherSlider;
