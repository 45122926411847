import React from "react";

import AOS from 'aos';
import 'aos/dist/aos.css';

import { Diplomas } from "./arrays/Diplomas";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCoverflow } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-coverflow';

import HystModal from "hystmodal";
import '../../node_modules/hystmodal/dist/hystmodal.min.css'

function DiplomaSlider() {

    AOS.init();

    const myModal = new HystModal({
        linkAttributeName: "data-hystmodal",
    });

    return (
        <div className="main__section__diplomas">
            <h1 className="main__section__diplomas__title">Сертификаты и дипломы наших педагогов</h1>
            <div className="main__section__diplomas__container">
                <Swiper
                    className='main__section__diplomas__container__slider'
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCoverflow]}
                    effect={'coverflow'}
                    grabCursor={true}
                    slidesPerView={3}
                    spaceBetween={10}
                    navigation
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    loopedSlides={null}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 50,
                        modifier: 1,
                        slideShadows: false,
                    }}
                    breakpoints={{
                        1520: {
                            slidesPerView: 3,
                        },
                        1200: {
                            slidesPerView: 3,
                        },
                        850: {
                            slidesPerView: 2,
                        },
                        0: {
                            slidesPerView: 1,
                        },
                    }}
                >
                    {Diplomas.map((data, key) => {
                        return (
                            <SwiperSlide key={key} className='main__section__diplomas__container__slider__slide'>
                                <img src={data.img} data-hystmodal={"#" + data.modal} alt="diplomas" className="main__section__diplomas__container__slider__slide__img" />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
            <div className="main__section__diplomas__card">
                {Diplomas.map((data, key) => {
                    return (
                        <div className="main__section__diplomas__popUp hystmodal" id={data.modal} aria-hidden="true">
                            <div key={key} className="main__section__diplomas__popUp__modal hystmodal__wrap">
                                <div className="main__section__diplomas__popUp__modal__content hystmodal__window" role="dialog" aria-modal="true">
                                    <button data-hystclose className="hystmodal__close">Закрыть</button>
                                    <img src={data.img} alt="diplomas" className="main__section__diplomas__popUp__modal__content__img" />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}


export default DiplomaSlider;
