import React from "react";
import { useState } from "react";

import { GroupRatesKids } from "./arrays/RatesKids";
import { IndividualRatesKids } from "./arrays/RatesKids";

import { GroupRatesTeens } from "./arrays/RatesTeens";
import { IndividualRatesTeens } from "./arrays/RatesTeens";

import { GroupRatesAdults } from "./arrays/RatesAdults";
import { IndividualRatesAdults } from "./arrays/RatesAdults";

// import '../css/rates.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCards } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-cards';


import gradient from '../img/gradient.svg'
import adv1 from '../img/advantage1.svg'
import waves2 from '../img/waves2.svg'



function Rates() {

    //section "rates" switcher
    const [selectedRate, setSelectedRate] = useState("kidsRate");
    const [selectedLink, setSelectedLink] = useState("kids"); // Добавлено состояние для отслеживания выбранной ссылки

    const handleAdultRateClick = () => {
        setSelectedRate("adultRate");
        setSelectedLink("adult"); // Обновление состояния выбранной ссылки
    }

    const handleKidsRateClick = () => {
        setSelectedRate("kidsRate");
        setSelectedLink("kids"); // Обновление состояния выбранной ссылки
    }

    const handleTeensRateClick = () => {
        setSelectedRate("teensRate");
        setSelectedLink("teens"); // Обновление состояния выбранной ссылки
    }


    //section "rates" switcher
    const [selectedDuration, setSelectedDuration] = useState("45min");
    const [selectedLinkDuration, setSelectedLinkDuration] = useState("45"); // Добавлено состояние для отслеживания выбранной ссылки

    const handle45nimutesClick = () => {
        setSelectedDuration("45min");
        setSelectedLinkDuration("45"); // Обновление состояния выбранной ссылки
    }

    const handle60minutesClick = () => {
        setSelectedDuration("60min");
        setSelectedLinkDuration("60"); // Обновление состояния выбранной ссылки
    }


    return (
        <div className="main__section__rates">
            <img loading="lazy" src={gradient} alt="gradient" className="main__section__rates__gradient" />
            <div className="main__section__rates__container">
                <h1 className="main__section__rates__container__title">Доступные программы</h1>
                <div className="main__section__rates__container__choice">
                    <button
                        className={`main__section__rates__container__choice__age ${selectedLink === "kids" ? "selected" : ""
                            }`}
                        onClick={handleKidsRateClick}
                    >
                        Дети
                        <span className="main__section__rates__container__choice__age__span">
                            от 7 до 11 лет
                        </span>
                    </button>
                    <button
                        className={`main__section__rates__container__choice__age ${selectedLink === "teens" ? "selected" : ""
                            }`}
                        onClick={handleTeensRateClick}
                    >
                        Подростки
                        <span className="main__section__rates__container__choice__age__span">
                            от 12 до 18 лет
                        </span>
                    </button>
                    <button
                        className={`main__section__rates__container__choice__age ${selectedLink === "adult" ? "selected" : ""
                            }`}
                        onClick={handleAdultRateClick}
                    >
                        Взрослые
                    </button>
                </div>
                <div className="main__section__rates__container__packages">
                    {selectedRate === "kidsRate" && (
                        <div className="main__section__rates__container__packages__age">
                            <div className="main__section__rates__container__packages__age__package">
                                <div className="main__section__rates__container__packages__age__package__title groupTitle">Групповые занятия</div>
                                <Swiper
                                    className='main__section__rates__container__packages__age__package__slider'
                                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCards]}
                                    effect="cards"
                                    grabCursor={true}
                                    slidesPerView={1}
                                    spaceBetween={10}
                                    navigation
                                    pagination={{ clickable: true }}
                                    loop={true}
                                    loopedSlides={null}
                                >
                                    {GroupRatesKids.map((data, key) => {
                                        return (
                                            <SwiperSlide key={key} className='main__section__rates__container__packages__age__package__slider__slide'>
                                                <div className="main__section__rates__container__packages__age__package__slider__slide__card">
                                                    <h3 className="main__section__rates__container__packages__age__package__slider__slide__card__h3">пакет</h3>
                                                    <h2 className="main__section__rates__container__packages__age__package__slider__slide__card__title">{`"` + data.name + `"`}</h2>
                                                    <p className="main__section__rates__container__packages__age__package__slider__slide__card__format">{data.format}</p>
                                                    <img loading="lazy" src={data.img} alt="Rates" className="main__section__rates__container__packages__age__package__slider__slide__card__img" />
                                                    <div className="main__section__rates__container__packages__age__package__slider__slide__card__features" >
                                                        {Object.values(data.features).map((features, index) => (
                                                            <li key={index} className="main__section__rates__container__packages__age__package__slider__slide__card__features__point" >	&#8226; {features}</li>
                                                        ))}
                                                    </div>
                                                    <div className="main__section__rates__container__packages__age__package__slider__slide__card__price"> {data.price} рублей
                                                        <div className="main__section__rates__container__packages__age__package__slider__slide__card__price__note">{data.count} занятий. Стоимость 1 занятия ({data.duration} минут) {data.pricePerLesson} руб.</div>
                                                    </div>
                                                    {/* <form action="https://securecardpayment.ru/sc/chmtnuLRQOqlLpTb" target="blank"> */}
                                                        <button 
                                                            className="main__section__rates__container__packages__age__package__slider__slide__card__button"
                                                            data-hystmodal='#paymentform'
                                                        >
                                                            Купить курс
                                                        </button>
                                                    {/* </form> */}
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </div>
                            <div className="main__section__rates__container__packages__age__package">
                                <div className="main__section__rates__container__packages__age__package__title">Индивидуальные занятия</div>
                                <div className="main__section__rates__container__packages__age__package__duration">
                                    <button
                                        className={`main__section__rates__container__packages__age__package__duration__time ${selectedLinkDuration === "45" ? "selected" : ""
                                            }`}
                                        onClick={handle45nimutesClick}
                                    >
                                        45 минут
                                    </button>
                                    <button
                                        className={`main__section__rates__container__packages__age__package__duration__time ${selectedLinkDuration === "60" ? "selected" : ""
                                            }`}
                                        onClick={handle60minutesClick}
                                    >
                                        60 минут
                                    </button>
                                </div>
                                {selectedDuration === "45min" && (
                                    <Swiper
                                        className='main__section__rates__container__packages__age__package__slider'
                                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCards]}
                                        effect="cards"
                                        grabCursor={true}
                                        slidesPerView={1}
                                        spaceBetween={10}
                                        navigation
                                        pagination={{ clickable: true }}
                                        loop={true}
                                        loopedSlides={null}
                                    >
                                        {IndividualRatesKids.map((data, key) => {
                                            return (
                                                <>
                                                    {Object.values(data.time["45min"]).map((time, index) => (
                                                        <SwiperSlide className='main__section__rates__container__packages__age__package__slider__slide'>
                                                            <div className="main__section__rates__container__packages__age__package__slider__slide__card">
                                                                <h3 className="main__section__rates__container__packages__age__package__slider__slide__card__h3">
                                                                    пакет
                                                                </h3>
                                                                <h2 className="main__section__rates__container__packages__age__package__slider__slide__card__title">
                                                                    {`"` + time.name + `"`}
                                                                </h2>
                                                                <p className="main__section__rates__container__packages__age__package__slider__slide__card__format">{time.format}</p>
                                                                <img loading="lazy" src={time.img} alt="time" className="main__section__rates__container__packages__age__package__slider__slide__card__img" />
                                                                <div className="main__section__rates__container__packages__age__package__slider__slide__card__features" >
                                                                    {Object.values(time.features).map((features, index) => (
                                                                        <li key={index} className="main__section__rates__container__packages__age__package__slider__slide__card__features__point" >
                                                                            &#8226; {features}
                                                                        </li>
                                                                    ))}
                                                                </div>
                                                                <div className="main__section__rates__container__packages__age__package__slider__slide__card__price">
                                                                    {time.price} рублей
                                                                    <div className="main__section__rates__container__packages__age__package__slider__slide__card__price__note">
                                                                        Стоимость 1 занятия ({time.duration} минут) {time.pricePerLesson} руб.
                                                                    </div>
                                                                </div>
                                                                {/* <form action="https://securecardpayment.ru/sc/chmtnuLRQOqlLpTb" target="blank"> */}
                                                                    <button 
                                                                        className="main__section__rates__container__packages__age__package__slider__slide__card__button"
                                                                        data-hystmodal='#paymentform'
                                                                    >
                                                                        Купить курс
                                                                    </button>
                                                                {/* </form> */}
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </>
                                            );
                                        })}
                                    </Swiper>
                                )}
                                {selectedDuration === "60min" && (
                                    <Swiper
                                        className='main__section__rates__container__packages__age__package__slider'
                                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCards]}
                                        effect="cards"
                                        grabCursor={true}
                                        slidesPerView={1}
                                        spaceBetween={10}
                                        navigation
                                        pagination={{ clickable: true }}
                                        loop={true}
                                        loopedSlides={null}
                                    >
                                        {IndividualRatesKids.map((data, key) => {
                                            return (
                                                <div key={key}>
                                                    {Object.values(data.time["60min"]).map((time, index) => (
                                                        <SwiperSlide key={index} className='main__section__rates__container__packages__age__package__slider__slide'>
                                                            <div className="main__section__rates__container__packages__age__package__slider__slide__card">
                                                                <h3 className="main__section__rates__container__packages__age__package__slider__slide__card__h3">
                                                                    пакет
                                                                </h3>
                                                                <h2 className="main__section__rates__container__packages__age__package__slider__slide__card__title">
                                                                    {`"` + time.name + `"`}
                                                                </h2>
                                                                <p className="main__section__rates__container__packages__age__package__slider__slide__card__format">{time.format}</p>
                                                                <img loading="lazy" src={time.img} alt="time" className="main__section__rates__container__packages__age__package__slider__slide__card__img" />
                                                                <div className="main__section__rates__container__packages__age__package__slider__slide__card__features" >
                                                                    {Object.values(time.features).map((features, index) => (
                                                                        <li key={index} className="main__section__rates__container__packages__age__package__slider__slide__card__features__point">
                                                                            &#8226; {features}
                                                                        </li>
                                                                    ))}
                                                                </div>
                                                                <div className="main__section__rates__container__packages__age__package__slider__slide__card__price">
                                                                    {time.price} рублей
                                                                    <div className="main__section__rates__container__packages__age__package__slider__slide__card__price__note">
                                                                        Стоимость 1 занятия ({time.duration} минут) {time.pricePerLesson} руб.
                                                                    </div>
                                                                </div>
                                                                {/* <form action="https://securecardpayment.ru/sc/chmtnuLRQOqlLpTb" target="blank"> */}
                                                                    <button 
                                                                        className="main__section__rates__container__packages__age__package__slider__slide__card__button"
                                                                        data-hystmodal='#paymentform'
                                                                    >
                                                                        Купить курс
                                                                    </button>
                                                                {/* </form> */}
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </div>
                                            );
                                        })}
                                    </Swiper>
                                )}
                            </div>
                        </div>
                    )}
                    {selectedRate === "teensRate" && (
                        <div className="main__section__rates__container__packages__age">
                            <div className="main__section__rates__container__packages__age__package">
                                <div className="main__section__rates__container__packages__age__package__title groupTitle">Групповые занятия</div>
                                <Swiper
                                    className='main__section__rates__container__packages__age__package__slider'
                                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCards]}
                                    effect="cards"
                                    grabCursor={true}
                                    slidesPerView={1}
                                    spaceBetween={10}
                                    navigation
                                    pagination={{ clickable: true }}
                                    loop={true}
                                    loopedSlides={null}
                                >
                                    {GroupRatesTeens.map((data, key) => {
                                        return (
                                            <SwiperSlide key={key} className='main__section__rates__container__packages__age__package__slider__slide'>
                                                <div className="main__section__rates__container__packages__age__package__slider__slide__card">
                                                    <h3 className="main__section__rates__container__packages__age__package__slider__slide__card__h3">пакет</h3>
                                                    <h2 className="main__section__rates__container__packages__age__package__slider__slide__card__title">{`"` + data.name + `"`}</h2>
                                                    <p className="main__section__rates__container__packages__age__package__slider__slide__card__format">{data.format}</p>
                                                    <img loading="lazy" src={data.img} alt="rates" className="main__section__rates__container__packages__age__package__slider__slide__card__img" />
                                                    <div className="main__section__rates__container__packages__age__package__slider__slide__card__features" >
                                                        {Object.values(data.features).map((features, index) => (
                                                            <li key={index} className="main__section__rates__container__packages__age__package__slider__slide__card__features__point" >	&#8226; {features}</li>
                                                        ))}
                                                    </div>
                                                    <div className="main__section__rates__container__packages__age__package__slider__slide__card__price"> {data.price} рублей
                                                        <div className="main__section__rates__container__packages__age__package__slider__slide__card__price__note">{data.count} занятий. Стоимость 1 занятия ({data.duration} минут) {data.pricePerLesson} руб.</div>
                                                    </div>
                                                    {/* <form action="https://securecardpayment.ru/sc/chmtnuLRQOqlLpTb" target="blank"> */}
                                                        <button 
                                                            className="main__section__rates__container__packages__age__package__slider__slide__card__button"
                                                            data-hystmodal='#paymentform'
                                                        >
                                                            Купить курс
                                                        </button>
                                                    {/* </form> */}
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </div>
                            <div className="main__section__rates__container__packages__age__package">
                                <div className="main__section__rates__container__packages__age__package__title">Индивидуальные занятия</div>
                                <div className="main__section__rates__container__packages__age__package__duration">
                                    <button
                                        className={`main__section__rates__container__packages__age__package__duration__time ${selectedLinkDuration === "45" ? "selected" : ""
                                            }`}
                                        onClick={handle45nimutesClick}
                                    >
                                        45 минут
                                    </button>
                                    <button
                                        className={`main__section__rates__container__packages__age__package__duration__time ${selectedLinkDuration === "60" ? "selected" : ""
                                            }`}
                                        onClick={handle60minutesClick}
                                    >
                                        60 минут
                                    </button>
                                </div>
                                {selectedDuration === "45min" && (
                                    <Swiper
                                        className='main__section__rates__container__packages__age__package__slider'
                                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCards]}
                                        effect="cards"
                                        grabCursor={true}
                                        slidesPerView={1}
                                        spaceBetween={10}
                                        navigation
                                        pagination={{ clickable: true }}
                                        loop={true}
                                        loopedSlides={null}
                                    >
                                        {IndividualRatesTeens.map((data, key) => {
                                            return (
                                                <>
                                                    {Object.values(data.time["45min"]).map((time, index) => (
                                                        <SwiperSlide className='main__section__rates__container__packages__age__package__slider__slide'>
                                                            <div className="main__section__rates__container__packages__age__package__slider__slide__card">
                                                                <h3 className="main__section__rates__container__packages__age__package__slider__slide__card__h3">
                                                                    пакет
                                                                </h3>
                                                                <h2 className="main__section__rates__container__packages__age__package__slider__slide__card__title">
                                                                    {`"` + time.name + `"`}
                                                                </h2>
                                                                <p className="main__section__rates__container__packages__age__package__slider__slide__card__format">{time.format}</p>
                                                                <img loading="lazy" src={time.img} alt="time" className="main__section__rates__container__packages__age__package__slider__slide__card__img" />
                                                                <div className="main__section__rates__container__packages__age__package__slider__slide__card__features" >
                                                                    {Object.values(time.features).map((features, index) => (
                                                                        <li key={index} className="main__section__rates__container__packages__age__package__slider__slide__card__features__point" >
                                                                            &#8226; {features}
                                                                        </li>
                                                                    ))}
                                                                </div>
                                                                <div className="main__section__rates__container__packages__age__package__slider__slide__card__price">
                                                                    {time.price} рублей
                                                                    <div className="main__section__rates__container__packages__age__package__slider__slide__card__price__note">
                                                                        Стоимость 1 занятия ({time.duration} минут) {time.pricePerLesson} руб.
                                                                    </div>
                                                                </div>
                                                                {/* <form action="https://securecardpayment.ru/sc/chmtnuLRQOqlLpTb" target="blank"> */}
                                                                    <button 
                                                                        className="main__section__rates__container__packages__age__package__slider__slide__card__button"
                                                                        data-hystmodal='#paymentform'
                                                                    >
                                                                        Купить курс
                                                                    </button>
                                                                {/* </form> */}
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </>
                                            );
                                        })}
                                    </Swiper>
                                )}
                                {selectedDuration === "60min" && (
                                    <Swiper
                                        className='main__section__rates__container__packages__age__package__slider'
                                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCards]}
                                        effect="cards"
                                        grabCursor={true}
                                        slidesPerView={1}
                                        spaceBetween={10}
                                        navigation
                                        pagination={{ clickable: true }}
                                        loop={true}
                                        loopedSlides={null}
                                    >
                                        {IndividualRatesTeens.map((data, key) => {
                                            return (
                                                <div key={key}>
                                                    {Object.values(data.time["60min"]).map((time, index) => (
                                                        <SwiperSlide key={index} className='main__section__rates__container__packages__age__package__slider__slide'>
                                                            <div className="main__section__rates__container__packages__age__package__slider__slide__card">
                                                                <h3 className="main__section__rates__container__packages__age__package__slider__slide__card__h3">
                                                                    пакет
                                                                </h3>
                                                                <h2 className="main__section__rates__container__packages__age__package__slider__slide__card__title">
                                                                    {`"` + time.name + `"`}
                                                                </h2>
                                                                <p className="main__section__rates__container__packages__age__package__slider__slide__card__format">{time.format}</p>
                                                                <img loading="lazy" src={time.img} alt="time" className="main__section__rates__container__packages__age__package__slider__slide__card__img" />
                                                                <div className="main__section__rates__container__packages__age__package__slider__slide__card__features" >
                                                                    {Object.values(time.features).map((features, index) => (
                                                                        <li key={index} className="main__section__rates__container__packages__age__package__slider__slide__card__features__point">
                                                                            &#8226; {features}
                                                                        </li>
                                                                    ))}
                                                                </div>
                                                                <div className="main__section__rates__container__packages__age__package__slider__slide__card__price">
                                                                    {time.price} рублей
                                                                    <div className="main__section__rates__container__packages__age__package__slider__slide__card__price__note">
                                                                        Стоимость 1 занятия ({time.duration} минут) {time.pricePerLesson} руб.
                                                                    </div>
                                                                </div>
                                                                {/* <form action="https://securecardpayment.ru/sc/chmtnuLRQOqlLpTb" target="blank"> */}
                                                                    <button 
                                                                        className="main__section__rates__container__packages__age__package__slider__slide__card__button"
                                                                        data-hystmodal='#paymentform'
                                                                    >
                                                                        Купить курс
                                                                    </button>
                                                                {/* </form> */}
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </div>
                                            );
                                        })}
                                    </Swiper>
                                )}
                            </div>
                        </div>
                    )}
                    {selectedRate === "adultRate" && (
                        <div className="main__section__rates__container__packages__age">
                            <div className="main__section__rates__container__packages__age__package">
                                <div className="main__section__rates__container__packages__age__package__title groupTitle">Групповые занятия</div>
                                <Swiper
                                    className='main__section__rates__container__packages__age__package__slider'
                                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCards]}
                                    effect="cards"
                                    grabCursor={true}
                                    slidesPerView={1}
                                    spaceBetween={10}
                                    navigation
                                    pagination={{ clickable: true }}
                                    loop={true}
                                    loopedSlides={null}
                                >
                                    {GroupRatesAdults.map((data, key) => {
                                        return (
                                            <SwiperSlide key={key} className='main__section__rates__container__packages__age__package__slider__slide'>
                                                <div className="main__section__rates__container__packages__age__package__slider__slide__card">
                                                    <h3 className="main__section__rates__container__packages__age__package__slider__slide__card__h3">пакет</h3>
                                                    <h2 className="main__section__rates__container__packages__age__package__slider__slide__card__title">{`"` + data.name + `"`}</h2>
                                                    <p className="main__section__rates__container__packages__age__package__slider__slide__card__format">{data.format}</p>
                                                    <img loading="lazy" src={data.img} alt="rates" className="main__section__rates__container__packages__age__package__slider__slide__card__img" />
                                                    <div className="main__section__rates__container__packages__age__package__slider__slide__card__features" >
                                                        {Object.values(data.features).map((features, index) => (
                                                            <li key={index} className="main__section__rates__container__packages__age__package__slider__slide__card__features__point" >	&#8226; {features}</li>
                                                        ))}
                                                    </div>
                                                    <div className="main__section__rates__container__packages__age__package__slider__slide__card__price"> {data.price} рублей
                                                        <div className="main__section__rates__container__packages__age__package__slider__slide__card__price__note">{data.count} занятий. Стоимость 1 занятия ({data.duration} минут) {data.pricePerLesson} руб.</div>
                                                    </div>
                                                    {/* <form action="https://securecardpayment.ru/sc/chmtnuLRQOqlLpTb" target="blank"> */}
                                                        <button 
                                                            className="main__section__rates__container__packages__age__package__slider__slide__card__button"
                                                            data-hystmodal='#paymentform'
                                                        >
                                                            Купить курс
                                                        </button>
                                                    {/* </form> */}
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </div>
                            <div className="main__section__rates__container__packages__age__package">
                                <div className="main__section__rates__container__packages__age__package__title">Индивидуальные занятия</div>
                                <div className="main__section__rates__container__packages__age__package__duration">
                                    <button
                                        className={`main__section__rates__container__packages__age__package__duration__time ${selectedLinkDuration === "45" ? "selected" : ""
                                            }`}
                                        onClick={handle45nimutesClick}
                                    >
                                        45 минут
                                    </button>
                                    <button
                                        className={`main__section__rates__container__packages__age__package__duration__time ${selectedLinkDuration === "60" ? "selected" : ""
                                            }`}
                                        onClick={handle60minutesClick}
                                    >
                                        60 минут
                                    </button>
                                </div>
                                {selectedDuration === "45min" && (
                                    <Swiper
                                        className='main__section__rates__container__packages__age__package__slider'
                                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCards]}
                                        effect="cards"
                                        grabCursor={true}
                                        slidesPerView={1}
                                        spaceBetween={10}
                                        navigation
                                        pagination={{ clickable: true }}
                                        loop={true}
                                        loopedSlides={null}
                                    >
                                        {IndividualRatesAdults.map((data, key) => {
                                            return (
                                                <>
                                                    {Object.values(data.time["45min"]).map((time, index) => (
                                                        <SwiperSlide className='main__section__rates__container__packages__age__package__slider__slide'>
                                                            <div className="main__section__rates__container__packages__age__package__slider__slide__card">
                                                                <h3 className="main__section__rates__container__packages__age__package__slider__slide__card__h3">
                                                                    пакет
                                                                </h3>
                                                                <h2 className="main__section__rates__container__packages__age__package__slider__slide__card__title">
                                                                    {`"` + time.name + `"`}
                                                                </h2>
                                                                <p className="main__section__rates__container__packages__age__package__slider__slide__card__format">{time.format}</p>
                                                                <img loading="lazy" src={time.img} alt="time" className="main__section__rates__container__packages__age__package__slider__slide__card__img" />
                                                                <div className="main__section__rates__container__packages__age__package__slider__slide__card__features" >
                                                                    {Object.values(time.features).map((features, index) => (
                                                                        <li key={index} className="main__section__rates__container__packages__age__package__slider__slide__card__features__point" >
                                                                            &#8226; {features}
                                                                        </li>
                                                                    ))}
                                                                </div>
                                                                <div className="main__section__rates__container__packages__age__package__slider__slide__card__price">
                                                                    {time.price} рублей
                                                                    <div className="main__section__rates__container__packages__age__package__slider__slide__card__price__note">
                                                                        Стоимость 1 занятия ({time.duration} минут) {time.pricePerLesson} руб.
                                                                    </div>
                                                                </div>
                                                                {/* <form action="https://securecardpayment.ru/sc/chmtnuLRQOqlLpTb" target="blank"> */}
                                                                    <button 
                                                                        className="main__section__rates__container__packages__age__package__slider__slide__card__button"
                                                                        data-hystmodal='#paymentform'
                                                                    >
                                                                        Купить курс
                                                                    </button>
                                                                {/* </form> */}
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </>
                                            );
                                        })}
                                    </Swiper>
                                )}
                                {selectedDuration === "60min" && (
                                    <Swiper
                                        className='main__section__rates__container__packages__age__package__slider'
                                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCards]}
                                        effect="cards"
                                        grabCursor={true}
                                        slidesPerView={1}
                                        spaceBetween={10}
                                        navigation
                                        pagination={{ clickable: true }}
                                        loop={true}
                                        loopedSlides={null}
                                    >
                                        {IndividualRatesAdults.map((data, key) => {
                                            return (
                                                <div key={key}>
                                                    {Object.values(data.time["60min"]).map((time, index) => (
                                                        <SwiperSlide key={index} className='main__section__rates__container__packages__age__package__slider__slide'>
                                                            <div className="main__section__rates__container__packages__age__package__slider__slide__card">
                                                                <h3 className="main__section__rates__container__packages__age__package__slider__slide__card__h3">
                                                                    пакет
                                                                </h3>
                                                                <h2 className="main__section__rates__container__packages__age__package__slider__slide__card__title">
                                                                    {`"` + time.name + `"`}
                                                                </h2>
                                                                <p className="main__section__rates__container__packages__age__package__slider__slide__card__format">{time.format}</p>
                                                                <img loading="lazy" src={time.img} alt="time" className="main__section__rates__container__packages__age__package__slider__slide__card__img" />
                                                                <div className="main__section__rates__container__packages__age__package__slider__slide__card__features" >
                                                                    {Object.values(time.features).map((features, index) => (
                                                                        <li key={index} className="main__section__rates__container__packages__age__package__slider__slide__card__features__point">
                                                                            &#8226; {features}
                                                                        </li>
                                                                    ))}
                                                                </div>
                                                                <div className="main__section__rates__container__packages__age__package__slider__slide__card__price">
                                                                    {time.price} рублей
                                                                    <div className="main__section__rates__container__packages__age__package__slider__slide__card__price__note">
                                                                        Стоимость 1 занятия ({time.duration} минут) {time.pricePerLesson} руб.
                                                                    </div>
                                                                </div>
                                                                {/* <form action="https://securecardpayment.ru/sc/chmtnuLRQOqlLpTb" target="blank"> */}
                                                                    <button 
                                                                        className="main__section__rates__container__packages__age__package__slider__slide__card__button"
                                                                        data-hystmodal='#paymentform'
                                                                    >
                                                                        Купить курс
                                                                    </button>
                                                                {/* </form> */}
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </div>
                                            );
                                        })}
                                    </Swiper>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="main__section__results__container__button" data-hystmodal='#form'>
                <button className="main__section__results__container__button__hover">Попробовать бесплатный урок</button>
            </div>
            <img loading="lazy" src={waves2} alt="waves" className="main__section__forWhom__waves" />
        </div>
    )
};

export default Rates;










