import React from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-cards';

import adv1 from '../img/advantage1.svg'
import adv2 from '../img/advantage2.svg'
import adv3 from '../img/advantage3.svg'
import adv4 from '../img/advantage4.svg'
import adv5 from '../img/advantage5.svg'
import adv6 from '../img/advantage6.svg'
import adv7 from '../img/advantage7.svg'
import adv8 from '../img/advantage8.svg'
import adv9 from '../img/advantage9.svg'
import adv10 from '../img/advantage10.svg'
import adv11 from '../img/advantage11.svg'
import gradient from '../img/gradient.svg'

function Advantages() {
    return (
        <>
            <img loading="lazy" src={gradient} alt="gradient" className="main__section__gradient" />
            <div className="main__section__advantages">
                <h1 className="main__section__advantages__h1">Почему мы?</h1>
                <div className="main__section__advantages__container">
                    <Swiper
                        className='main__section__advantages__container__slider'
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        grabCursor={true}
                        slidesPerView={3}
                        spaceBetween={10}
                        navigation
                        pagination={{ clickable: true }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        loopedSlides={null}
                        breakpoints={{
                            1300: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },
                            900: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                        }}
                    >
                        <SwiperSlide className='main__section__advantages__container__slider__slide'>
                            <img  src={adv1} alt='advantages' className='main__section__advantages__container__slider__slide__img' />
                            <div className='main__section__advantages__container__slider__slide__name'>Более 3 лех работы</div>
                        </SwiperSlide>
                        <SwiperSlide className='main__section__advantages__container__slider__slide'>
                            <img  src={adv2} alt='advantages' className='main__section__advantages__container__slider__slide__img' />
                            <div className='main__section__advantages__container__slider__slide__name'>500+ довольных учеников</div>
                        </SwiperSlide>
                        <SwiperSlide className='main__section__advantages__container__slider__slide'>
                            <img  src={adv3} alt='advantages' className='main__section__advantages__container__slider__slide__img' />
                            <div className='main__section__advantages__container__slider__slide__name'>Выгодная цена</div>
                        </SwiperSlide>
                        <SwiperSlide className='main__section__advantages__container__slider__slide'>
                            <img  src={adv4} alt='advantages' className='main__section__advantages__container__slider__slide__img' />
                            <div className='main__section__advantages__container__slider__slide__name'>Бесплатное пробное занятие</div>
                        </SwiperSlide>
                        <SwiperSlide className='main__section__advantages__container__slider__slide'>
                            <img  src={adv5} alt='advantages  ' className='main__section__advantages__container__slider__slide__img' />
                            <div className='main__section__advantages__container__slider__slide__name'>Учитываем Ваши цели и задачи в изучении языка</div>
                        </SwiperSlide>
                        <SwiperSlide className='main__section__advantages__container__slider__slide'>
                            <img  src={adv6} alt='advantages  ' className='main__section__advantages__container__slider__slide__img' />
                            <div className='main__section__advantages__container__slider__slide__name'>Интересные материалы для изучения</div>
                        </SwiperSlide>
                        <SwiperSlide className='main__section__advantages__container__slider__slide'>
                            <img  src={adv7} alt='advantages  ' className='main__section__advantages__container__slider__slide__img' />
                            <div className='main__section__advantages__container__slider__slide__name'>Интересные и креативные домашние задания на платформе</div>
                        </SwiperSlide>
                        <SwiperSlide className='main__section__advantages__container__slider__slide'>
                            <img  src={adv8} alt='advantages  ' className='main__section__advantages__container__slider__slide__img' />
                            <div className='main__section__advantages__container__slider__slide__name'>Квалифицированные преподаватели, умеющие работать онлайн </div>
                        </SwiperSlide>
                        <SwiperSlide className='main__section__advantages__container__slider__slide'>
                            <img  src={adv9} alt='advantages  ' className='main__section__advantages__container__slider__slide__img' />
                            <div className='main__section__advantages__container__slider__slide__name'>Форматы занятий: индивидуально и мини-группы (до 5 человек)</div>
                        </SwiperSlide>
                        <SwiperSlide className='main__section__advantages__container__slider__slide'>
                            <img  src={adv10} alt='advantages  ' className='main__section__advantages__container__slider__slide__img' />
                            <div className='main__section__advantages__container__slider__slide__name'>Ежемесячная подробная обратная связь + прогресс на платформе</div>
                        </SwiperSlide>
                        <SwiperSlide className='main__section__advantages__container__slider__slide'>
                            <img  src={adv11} alt='advantages  ' className='main__section__advantages__container__slider__slide__img' />
                            <div className='main__section__advantages__container__slider__slide__name'>200+ интерактивных заданий и 50+ игр на нашей платформе для изучения языка</div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    )
};

export default Advantages;




